<template>
  <section id="fichaTecnica">
    <FichaTecnica />
    <Footer />
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "Ficha_Tecnica",
  components: {
    FichaTecnica: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FichaTecnica" */ "@/components/fichaTecnica/FichaTecnicaPrincipal.vue"
      )
    ),
    Footer: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Footer-politica" */ "@/components/footer/Footer.vue")
    ),
  },
};
</script>